@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #111111;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-style: normal;
  font-weight: bold;
  word-wrap: break-word;
  font-size: 16px;
  min-width: 100%;
  overflow: hidden;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .inner {
    padding: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .inner {
    padding: 64px 10px 20px;
  }
}

.inner2 {
  max-width: 800px;
  margin: 0 auto;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1200px;
  padding-bottom: 100px;
}

@media only screen and (max-width: 834px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

a {
  transition: 1s;
}

.imgR, .imgL {
  float: none	!important;
  margin-left: 0;
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  float: none;
  max-width: 100% !important;
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
  border-top: 10px solid;
  border-image: linear-gradient(to right, #8e7bde 0%, #d8c7f6 100%);
  border-image-slice: 1;
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

@media only screen and (max-width: 834px) {
  .hed_left {
    padding-bottom: 15px;
  }
}

.hed_cont a {
  width: 300px;
  background: #272e77;
  margin: 0 auto;
  color: #ffffff;
  font-size: 1.25em;
  padding: 12px;
  height: 54px;
  display: block;
  white-space: nowrap;
}

.hed_cont a img {
  margin-right: 7px;
}

.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 10px;
}

@media only screen and (max-width: 834px) {
  .wrap {
    display: block;
    padding: 50px 10px;
  }
}

.wrap .hed_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .wrap .hed_right {
    justify-content: center;
  }
}

@media only screen and (max-width: 640px) {
  .wrap .hed_right {
    display: block;
  }
}

.wrap .hed_right .tel_time .tel2 {
  font-size: 2.1em;
  font-weight: 900;
  color: #272e77;
  margin-right: 15px;
}

.wrap .hed_right .tel_time .tel2 img {
  margin-right: 7px;
}

.wrap .hed_right .tel_time .tel2 a {
  color: #272e77;
  font-weight: 900;
}

.wrap .hed_right .tel_time .time {
  font-size: 13px;
  text-align: left;
  color: #272e77;
}

@media only screen and (max-width: 640px) {
  .wrap .hed_right .tel_time .time {
    text-align: center;
  }
}

.wrap .hed_right .fax {
  font-size: 2.1em;
  font-weight: 900;
  color: #272e77;
  margin-right: 15px;
  margin-bottom: 10px;
}

.wrap .hed_right .fax img {
  margin-right: 7px;
}

#header {
  transition: 0.5s ease-in-out;
  padding-bottom: 25px;
}

@media only screen and (max-width: 834px) {
  #header {
    padding: 0;
  }
}

#header #siteID {
  text-align: left;
}

@media only screen and (max-width: 834px) {
  #header #siteID {
    text-align: center;
  }
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  #header #siteID {
    text-align: center;
    margin-bottom: 15px;
  }
}

#header #siteID a {
  opacity: 1;
  display: block;
}

.fixed {
  position: fixed !important;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
  background: rgba(250, 250, 250, 0.9);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-top: 0 !important;
  padding: 10px;
}

#hed_info .address {
  text-align: right;
}

@media only screen and (max-width: 640px) {
  #hed_info .address {
    text-align: center;
  }
}

.navwrap {
  transition: 0.5s ease-in-out;
}

@media screen and (min-width: 834px) and (max-width: 1540px) {
  .navwrap {
    width: 100%;
  }
}

#nav_global {
  width: 100%;
}

@media only screen and (max-width: 834px) {
  #nav_global {
    display: none;
  }
}

#nav_global .inner {
  padding: 0;
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  #nav_global ul {
    justify-content: center;
  }
}

#nav_global ul .pcnone {
  display: none;
}

#nav_global ul .spnone {
  display: block;
}

@media only screen and (max-width: 640px) {
  #nav_global ul .spnone {
    display: none;
  }
}

#nav_global ul li {
  z-index: 1;
  position: relative;
  border-right: 1px solid #ccc;
  width: 100%;
}

#nav_global ul li:first-of-type {
  border-left: 1px solid #ccc;
}

#nav_global ul li a {
  width: 100%;
  padding: 0 10px;
  display: block;
  color: #272e77;
}

#nav_global ul li a span {
  display: block;
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  #nav_global ul li a {
    font-size: 14px;
  }
}

#nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

#nav_global ul ul {
  position: absolute;
  width: 200px;
  z-index: 100;
  display: block;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
  left: -65%;
  right: 0;
}

#nav_global ul ul li {
  border-bottom: 1px solid #6157ba;
  font-size: 14px;
  border-bottom: none;
}

#nav_global ul ul li a {
  color: #111111;
  padding: 15px 0;
}

@media only screen and (max-width: 640px) {
  .mainArea {
    margin: auto;
  }
}

.mainArea img {
  object-fit: cover;
  font-family: 'object-fit:cover;';
}

@media only screen and (max-width: 834px) {
  .mainArea img {
    height: 250px !important;
    width: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
  }
}

.img_bnr a {
  width: 100% !important;
}

.mainwrap {
  position: relative;
}

.mainwrap .main_txt {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: auto;
  z-index: 1;
  padding: 30px;
}

#local-keyvisual {
  position: relative;
  z-index: 0;
}

#local-keyvisual:empty {
  display: none;
}

#local-keyvisual h1 {
  color: #ffffff;
  font-size: 3em;
  z-index: 1;
  position: relative;
  text-align: left;
  line-height: 0.9em;
  width: 100%;
  padding: 50px 10px;
  max-width: 1200px;
  font-weight: bold;
  margin: 0 auto;
  text-shadow: 3px 3px 6px #272e77;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 2em;
  }
}

#local-keyvisual h1:empty {
  display: none;
}

#slider {
  position: relative;
  z-index: 1;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-size: cover;
  background-position: center;
}

#local-keyvisual #local-keyvisual-bg:empty, #local-keyvisual .local-keyvisual-bg:empty {
  display: none;
}

#local-keyvisual-title {
  background: linear-gradient(to right, #8e7bde 0%, #d8c7f6 100%);
}

#local-keyvisual-title:empty {
  display: none;
}

.pan1 {
  margin: 20px 0 100px;
}

.pan1 a {
  color: #000;
}

.toggleMenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 49px;
  width: 49px;
  background-size: contain;
  position: fixed;
  top: 11px;
  right: 5px;
  z-index: 100;
  transition: 0.5s ease-in-out;
  background: #6157ba;
  border-radius: 10px;
}

.toggleMenu:after {
  content: 'MENU';
  font-size: 13px;
  color: #6157ba;
  display: block;
  position: absolute;
  bottom: -50%;
  left: 8%;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .toggleMenu {
    width: 49px;
  }
}

.hide {
  transform: translateY(-200%);
}

.toggleMenu .bars {
  margin: 0 30px 0 15px;
  height: 22px;
  width: 35px;
  position: relative;
}

.toggleMenu a:hover {
  opacity: 1;
}

.toggleMenu .bars span {
  height: 3px;
  background-color: #ffffff;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  position: absolute;
  left: -5px;
}

.toggleMenu .bars span:nth-of-type(1) {
  width: 30px;
  top: 0;
}

.toggleMenu .bars span:nth-of-type(2) {
  width: 30px;
  top: 10px;
}

.toggleMenu .bars span:nth-of-type(3) {
  width: 30px;
  top: 20px;
}

.toggleMenu.isActive .bars span {
  right: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(1), .toggleMenu.isActive .bars span:nth-of-type(3) {
  width: 30px;
  top: 10px;
}

.toggleMenu.isActive .bars span:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.toggleMenu.isActive .bars span:nth-of-type(2) {
  opacity: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.overlayMenu {
  width: 100%;
  -webkit-transition: -webkit-transform .8s ease;
  transition: -webkit-transform .8s ease;
  transition: transform .8s ease;
  transition: transform .8s ease, -webkit-transform .8s ease;
  z-index: 10;
  position: fixed;
  top: 0;
  right: 100%;
  bottom: 0;
  background: url(../images/home/pattern01.gif);
  opacity: 0;
  transition: opacity .6s ease, visibility .6s ease;
}

.fixed .overlayMenu {
  visibility: visible;
  opacity: 1;
}

.overlayMenu.isOpened {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.overlayMenu > .outer {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 100px !important;
}

.overlayMenu > .outer .inner {
  padding: 0 100px;
}

.overlayMenu > .outer .sp_hed_right {
  margin-top: 80px;
}

@media only screen and (max-width: 834px) {
  .overlayMenu > .outer .sp_hed_right {
    display: block;
  }
  .overlayMenu > .outer .sp_hed_right .tel2 {
    font-size: 1.5em;
    color: #ffffff;
  }
  .overlayMenu > .outer .sp_hed_right .tel2 img {
    margin-right: 7px;
  }
  .overlayMenu > .outer .sp_hed_right .tel2 a {
    color: #272e77;
    font-weight: bold;
  }
  .overlayMenu > .outer .sp_hed_right .time {
    color: #272e77;
    font-size: 13px;
  }
  .overlayMenu > .outer .sp_hed_right .fax {
    font-size: 1.5em;
    color: #272e77;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .overlayMenu > .outer .sp_hed_right .fax img {
    margin-right: 7px;
  }
  .overlayMenu > .outer .sp_hed_right .hed_cont a {
    background: #272e77;
    color: #ffffff;
    font-size: 1.3em;
    padding: 10px;
    display: block;
    max-width: 300px;
    margin: 0 auto 10px;
  }
  .overlayMenu > .outer .sp_hed_right .hed_cont a img {
    margin-right: 7px;
  }
}

@media only screen and (max-width: 834px) and (max-width: 834px) {
  .overlayMenu > .outer .sp_hed_right .hed_cont a {
    font-size: 1em;
    padding: 10px;
  }
}

@media only screen and (max-width: 834px) {
  .overlayMenu > .outer {
    padding: 20px 0;
  }
}

.overlayMenu > .outer li {
  border-bottom: 1px solid #272e77;
}

.overlayMenu > .outer li a {
  color: #272e77;
  padding: 10px;
  display: block;
  font-size: 24px;
  font-weight: bold;
}

.overlayMenu > .outer li a span {
  display: block;
}

.overlayMenu:before, .overlayMenu:after {
  content: "";
  height: 20px;
  background-size: cover;
  position: absolute;
  left: 10px;
  right: 10px;
}

.overlayMenu:before {
  top: 0;
}

.overlayMenu:after {
  bottom: 0;
}

@media (max-width: 835px) {
  .pcOnly {
    display: none !important;
  }
}

@media (min-width: 835px) {
  .smOnly {
    display: none !important;
  }
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  text-align: center;
  background: #272e77;
}

footer#global_footer .inner {
  padding: 50px 10px;
}

@media only screen and (max-width: 834px) {
  footer#global_footer .inner {
    padding: 30px 0;
  }
}

footer#global_footer a {
  text-decoration: none;
}

@media only screen and (max-width: 834px) {
  footer#global_footer #nav_footer {
    margin-top: 30px;
  }
}

footer#global_footer #nav_footer ul {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

@media only screen and (max-width: 834px) {
  footer#global_footer #nav_footer ul {
    display: block;
  }
}

footer#global_footer #nav_footer li {
  width: 30%;
  text-align: right;
}

@media only screen and (max-width: 834px) {
  footer#global_footer #nav_footer li {
    width: auto;
  }
}

footer#global_footer #nav_footer li a {
  text-decoration: none;
  padding: 0 10px;
  display: block;
  color: #ffffff;
  font-weight: bold;
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  footer#global_footer #nav_footer li a {
    font-size: 14px;
    padding: 0 5px;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 834px) {
  footer#global_footer #nav_footer li a {
    background: #f7eaf6;
    color: #111111;
    border-radius: 5px;
    width: 230px;
    margin: 5px auto;
    padding: 5px;
    text-align: center;
  }
}

footer#global_footer #nav_footer li a span {
  display: block;
  font-size: 12px;
  font-weight: normal;
}

@media only screen and (max-width: 834px) {
  footer#global_footer #nav_footer li a span {
    line-height: 1em;
  }
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #fff;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

#copy {
  color: #fff;
}

.pagetop {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 2;
  opacity: 1;
}

.pagetop a {
  display: block;
  color: #ffffff;
  background: #272e77;
  height: 60px;
  width: 60px;
  display: block;
  border-radius: 5px;
  font-size: 1.5em;
  padding: 15px;
}

#footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #footer {
    display: block;
  }
}

.ftmap iframe {
  width: 960px;
  height: 340px;
}

.ftlogo {
  margin-bottom: 30px;
  text-align: left;
}

@media only screen and (max-width: 834px) {
  .ftlogo {
    text-align: center;
  }
}

.address {
  font-size: 14px;
  line-height: 1.5em;
  text-align: left;
  color: #ffffff;
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .address {
    text-align: center;
  }
}

@media only screen and (max-width: 834px) {
  .address {
    text-align: center;
  }
}

#footer2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #footer2 {
    display: block;
  }
}

.ft_L .address {
  line-height: 1.7em;
}

#footer1 {
  background: url(../images/home/contact_bg.jpg) center;
  background-size: cover;
  padding: 100px 10px !important;
}

@media only screen and (max-width: 834px) {
  #footer1 {
    background-position: left;
  }
}

#footer1 .fth2 {
  font-size: 1.8em;
  margin-bottom: 40px;
  font-weight: 900;
  color: #272e77;
  text-align: left;
}

@media only screen and (max-width: 640px) {
  #footer1 .fth2 {
    text-align: center;
  }
}

#footer1 .fth2 br {
  display: none;
}

@media only screen and (max-width: 834px) {
  #footer1 .fth2 br {
    display: block;
  }
}

@media only screen and (max-width: 834px) {
  #footer1 .fth2 {
    font-size: 1.5em;
  }
}

#footer1 .ft_txt {
  text-align: left;
  margin-bottom: 40px;
}

#footer1 .ft_teltime {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 15px;
}

@media only screen and (max-width: 640px) {
  #footer1 .ft_teltime {
    display: block;
  }
}

#footer1 .ftcont_wrap {
  margin-left: 50%;
}

@media only screen and (max-width: 834px) {
  #footer1 .ftcont_wrap {
    margin-left: 0;
    background-color: rgba(247, 233, 246, 0.9);
    padding: 30px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  #footer1 .ftcont_wrap {
    margin-left: 30%;
  }
}

#footer1 .ftcont_wrap .ftcont a {
  background: #272e77;
  color: #ffffff;
  padding: 12px;
  display: block;
  width: 300px;
  font-size: 1.25em;
  white-space: nowrap;
}

#footer1 .ftcont_wrap .ftcont a img {
  margin-right: 7px;
}

@media only screen and (max-width: 640px) {
  #footer1 .ftcont_wrap .ftcont a {
    margin: 15px auto;
  }
}

#footer1 .tel2 {
  font-size: 1.5em;
  color: #272e77;
  font-weight: 900;
  margin-right: 30px;
}

@media only screen and (max-width: 640px) {
  #footer1 .tel2 {
    margin-right: 0;
  }
}

#footer1 .tel2 img {
  margin-right: 7px;
}

#footer1 .tel2 a {
  font-weight: 900;
  color: #272e77;
  display: block;
}

#footer1 .time {
  color: #272e77;
  text-align: left;
}

@media only screen and (max-width: 640px) {
  #footer1 .time {
    text-align: center;
    margin-bottom: 10px;
  }
}

#footer1 .fax {
  font-size: 1.5em;
  color: #272e77;
  font-weight: 900;
}

#footer1 .fax img {
  margin-right: 7px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #footer1 .fax {
    font-size: 1.25em;
  }
}

@media only screen and (max-width: 640px) {
  #footer1 .fax {
    margin: 0 auto;
  }
}

/* box */
.instagram .sns_text {
  display: none;
}

.instagram div.sns_list {
  display: flex;
  flex-direction: inherit !important;
  flex-wrap: wrap;
  border: none !important;
  justify-content: center;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .instagram div.sns_list {
    justify-content: space-between;
  }
}

.instagram div.sns_list > div {
  width: 18% !important;
}

@media only screen and (max-width: 640px) {
  .instagram div.sns_list > div {
    width: 47% !important;
    margin: 5px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .instagram div.sns_list > div {
    width: 31% !important;
    margin: 5px;
  }
}

.instagram div.sns_list div.sns_photo {
  width: 100% !important;
  padding: 0 !important;
}

.instagram div.sns_list > div {
  border-bottom: none !important;
}

.instagram .sns_photo img {
  width: 200px !important;
  height: 200px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media only screen and (max-width: 640px) {
  .instagram .sns_photo img {
    height: 230px !important;
    width: 230px !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .instagram .sns_photo img {
    height: 260px !important;
    width: 260px !important;
  }
}

.h2_01 h2 {
  font-size: 1.8em;
  line-height: 1.2em;
  color: #272e77;
  font-weight: bold;
}

@media only screen and (max-width: 834px) {
  .h2_01 h2 {
    font-size: 1.5em;
  }
}

.h2_02 h2 {
  font-size: 1.8em;
  line-height: 1.2em;
  color: #ffffff;
  font-weight: bold;
}

@media only screen and (max-width: 834px) {
  .h2_02 h2 {
    font-size: 1.5em;
  }
}

.txt {
  line-height: 2em;
}

.btn a {
  display: block;
  padding: 15px 0;
  color: #ffffff;
  font-weight: bold;
  position: relative;
  background: #272e77;
  border-radius: 5px;
  max-width: 400px;
  margin: 0 auto;
}

.btn a:after {
  font-family: "Font Awesome 5 pro";
  content: "\f0da";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: 3%;
  /*アイコンの位置*/
  top: 25%;
  /*アイコンの位置*/
  color: #ffffff;
  /*アイコン色*/
  font-weight: bold;
}

.btn2 a {
  display: block;
  padding: 15px 0;
  color: #ffffff;
  font-weight: bold;
  position: relative;
  background: #272e77;
  border-radius: 5px;
  max-width: 400px;
}

@media only screen and (max-width: 834px) {
  .btn2 a {
    max-width: 100%;
  }
}

.btn2 a:after {
  font-family: "Font Awesome 5 pro";
  content: "\f0da";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: 3%;
  /*アイコンの位置*/
  top: 25%;
  /*アイコンの位置*/
  color: #ffffff;
  /*アイコン色*/
  font-weight: bold;
}

.sec_01wrap {
  background: url(../images/home/pattern01.gif);
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  padding: 100px 10px;
}

.sec_01 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.sec_01:before {
  content: url(../images/home/cont1_icon.png);
  margin-right: 15px;
}

.sec_02wrap {
  background: linear-gradient(to right, #8e7bde 70%, #d8c7f6 100%);
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  padding: 80px 10px;
}

@media only screen and (max-width: 834px) {
  .sec_02wrap {
    background: #6157ba;
  }
}

@media only screen and (max-width: 834px) {
  .sec_02 {
    padding: 0;
  }
}

.sec_03 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .sec_03 {
    display: block;
  }
  .sec_03 .flxL {
    width: 100% !important;
  }
  .sec_03 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.sec_03 .flxL {
  width: 48%;
}

.sec_03 .flxR {
  width: 48%;
}

.itembox {
  justify-content: center;
}

.itembox .box {
  text-align: center;
}

.itembox .box img {
  margin: 0 auto;
}

@media only screen and (max-width: 834px) {
  .itembox .box img {
    width: 100% !important;
  }
}

.owl-dots {
  text-align: center;
  margin: 50px auto 0;
  width: 100%;
}

.owl-dots .active span {
  background: #272e77;
}

.owl-dots:active span {
  background: #272e77 !important;
}

.owl-dots span {
  width: 15px;
  height: 15px;
  background: #333;
  border-radius: 50%;
  display: block !important;
  text-align: center;
  margin: 5px;
}

.dl_access dt {
  background: #272e77 !important;
  border: none !important;
  flex-direction: inherit !important;
  font-weight: bold;
  width: 150px !important;
  height: 43px;
  display: inline-block;
}

.dl_access dd {
  border: none !important;
}

@media only screen and (max-width: 640px) {
  .dl_access dd {
    padding: 4px !important;
  }
}

.dl_access dl {
  margin-bottom: 20px;
}

.btnbox {
  justify-content: space-between;
}

.btnbox .box {
  width: 29%;
}

@media only screen and (max-width: 640px) {
  .btnbox .box {
    width: 100% !important;
    margin: 10px 0;
  }
}

.btnbox .box img {
  width: 100% !important;
}

.img-animation {
  overflow: hidden;
  position: relative;
}

.img-animation.animated:after {
  -webkit-animation: img-animation 1.5s cubic-bezier(0.77, 0, 0.175, 1) both;
  animation: img-animation 1.5s cubic-bezier(0.77, 0, 0.175, 1) both;
  background: #fff;
  bottom: 0;
  content: '';
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

@-webkit-keyframes img-animation {
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes img-animation {
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.contbox {
  display: flex;
  justify-content: space-between;
}

.contbox .box {
  width: 30%;
}

@media only screen and (max-width: 640px) {
  .contbox .box {
    width: 47%;
  }
}

.contbox .box img {
  border-bottom: 5px solid #6157ba;
}

.contbox .box article div {
  font-size: 1.25em;
  font-weight: bold;
  color: #272e77;
  text-align: center;
  padding: 10px;
}

.contbox .box img {
  width: 100% !important;
}

.bnrbox {
  justify-content: center;
}

.bnrbox .box {
  width: 38%;
  margin: 10px;
}

@media only screen and (max-width: 834px) {
  .bnrbox .box {
    width: 45%;
  }
}

.bnrbox .box img {
  width: 100% !important;
}

.newswrap .news {
  height: 200px;
  overflow-y: auto;
  color: #ffffff;
}

@media only screen and (max-width: 834px) {
  .newswrap .news {
    padding-left: 0;
    font-size: 16px;
  }
}

.newswrap .news dt {
  border: none !important;
  padding: 0 !important;
  font-weight: bold;
  flex-direction: inherit !important;
  justify-content: flex-start !important;
}

@media only screen and (max-width: 640px) {
  .newswrap .news dt {
    display: block !important;
    width: 100% !important;
  }
}

.newswrap .news dd {
  border: none !important;
  padding: 0 !important;
}

@media only screen and (max-width: 640px) {
  .newswrap .news dd {
    width: 100% !important;
  }
}

.newswrap .news dl {
  border-bottom: 1px dashed #fff !important;
  padding: 20px 0 !important;
}

@media only screen and (max-width: 640px) {
  .newswrap .news dl {
    display: block !important;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 2px #777;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  box-shadow: none;
}

/* title */
.h2_03 {
  background: #f7eaf6;
  padding: 15px;
  border-bottom: 1px solid #ccc;
}

.h2_03 h2 {
  font-size: 1.8em;
  position: relative;
  padding-left: 15px;
  font-weight: bold;
  line-height: 1.5em;
}

@media only screen and (max-width: 834px) {
  .h2_03 h2 {
    font-size: 1.5em;
  }
}

.h2_03 h2:before {
  content: '';
  width: 10px;
  height: 15px;
  border-radius: 10px;
  background: #6157ba;
  position: absolute;
  left: 0;
  top: 35%;
}

.h3_01 h3 {
  font-size: 1.25em;
  font-weight: bold;
  border-bottom: 1px dashed #6157ba;
  padding-bottom: 5px;
}

.h3_01 h3 span {
  font-size: 1.5em;
  color: #6157ba;
  margin-right: 7px;
  font-weight: 900;
}

.btnlist .tel2, .btnlist .fax {
  font-size: 1.5em;
  font-weight: bold;
  color: #272e77;
  background: #f7eaf6;
  padding: 10px;
  margin: 5px;
}

.btnlist .tel2 a, .btnlist .fax a {
  color: #272e77;
  font-weight: bold;
  display: block;
}

.btnlist li {
  width: 50%;
}

@media only screen and (max-width: 640px) {
  .btnlist li {
    float: none !important;
    width: 100%;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .btnlist li {
    width: 50%;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .btnlist li {
    width: 100%;
  }
}

.btnlist li .cont {
  background: #272e77;
  padding: 10px;
  border-radius: 10px;
  display: block;
  color: #ffffff;
  margin: 5px;
  font-weight: bold;
  position: relative;
}

.btnlist li .cont:after {
  font-family: "Font Awesome 5 pro";
  content: "\f0da";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: 3%;
  /*アイコンの位置*/
  top: 25%;
  /*アイコンの位置*/
  color: #ffffff;
  /*アイコン色*/
  font-weight: bold;
}

.cont_01 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .cont_01 {
    display: block;
  }
  .cont_01 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .cont_01 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.cont_01 .flxL {
  width: 32%;
}

.cont_01 .flxR {
  width: 67%;
}

.cont_02 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .cont_02 {
    display: block;
  }
  .cont_02 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .cont_02 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.cont_02 .flxL {
  width: 40%;
}

.cont_02 .flxL .img img {
  width: 100% !important;
}

.cont_02 .flxR {
  width: 58%;
}

.cont_06 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .cont_06 {
    display: block;
  }
  .cont_06 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .cont_06 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .cont_06 {
    display: block;
  }
  .cont_06 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .cont_06 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.cont_06 .flxL {
  width: 40%;
}

.cont_06 .flxR {
  width: 58%;
}

.cont_07 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .cont_07 {
    display: block;
  }
  .cont_07 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .cont_07 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.cont_07 .flxL {
  width: 48%;
}

.cont_07 .flxR {
  width: 48%;
}

.cont_08 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .cont_08 {
    display: block;
  }
  .cont_08 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .cont_08 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.cont_08 .flxL {
  width: 48%;
}

.cont_08 .flxR {
  width: 48%;
}

.dl_price2 dt {
  background: #272e77 !important;
  flex-direction: inherit !important;
  align-items: center !important;
  font-weight: bold;
  font-size: 22px;
  border: none !important;
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .dl_price2 dt {
    font-size: 18px;
  }
}

@media only screen and (max-width: 834px) {
  .dl_price2 dt {
    font-size: 18px;
  }
}

.dl_price2 dd {
  color: #111111;
  font-size: 45px;
  border: none !important;
  font-weight: bold;
}

@media only screen and (max-width: 834px) {
  .dl_price2 dd {
    font-size: 30px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .dl_price2 dd {
    font-size: 30px;
  }
}

.dl_price2 dd .small {
  font-size: 18px;
  font-weight: bold;
}

.td_cont {
  background: #f7f7f7;
  padding: 20px;
}

.td_cont td {
  border: none !important;
}

.td_cont .img {
  width: 20%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .td_cont .img {
    width: 50%;
  }
}

.td_cont .coordinator {
  border-bottom: 1px dotted #272e77 !important;
  padding-bottom: 5px !important;
}

.td_cont .coordinator .midashi {
  font-size: 1.25em;
  font-weight: bold;
}

.txt {
  line-height: 2em;
}

.cont_tel div {
  font-size: 1.5em;
  color: #111111;
  font-weight: bold;
}

.cont_tel a {
  color: #111111;
  font-weight: bold;
}

.cont_tel .time {
  font-size: 16px;
  background: #f7f7f7;
  padding: 5px;
  text-align: center;
  display: inline-block;
  margin-top: 15px;
}

.cont_form {
  padding: 30px;
  background: #f7f7f7;
}

@media only screen and (max-width: 640px) {
  .cont_form {
    padding: 30px;
  }
}

.cont_form dl {
  border-bottom: 1px solid #fff;
  margin: 10px;
}

.dl_01 dt {
  flex-direction: inherit !important;
  align-items: center !important;
}

.list_management li {
  font-size: 1.25em;
  font-weight: bold;
  padding: 5px 0;
}

.dl_02 dt {
  border: none !important;
  border-bottom: 1px solid #6157ba !important;
}

@media only screen and (max-width: 640px) {
  .dl_02 dt {
    width: 25% !important;
  }
}

.dl_02 dd {
  border: none !important;
  border-bottom: 1px solid #ccc !important;
}

@media only screen and (max-width: 640px) {
  .dl_02 dd {
    width: 75% !important;
  }
}

.dl_02 dd .back {
  background: #f7f7f7;
  width: 50px;
  padding: 5px;
  display: inline-block;
  text-align: center;
  margin-bottom: 5px;
  font-weight: bold;
}

.qa dl {
  display: block !important;
  margin: 15px 0;
  background: #f7f7f7;
  padding: 15px;
}

.qa dt {
  display: block !important;
  width: 100% !important;
  border: none !important;
  position: relative;
  padding: 15px 0 15px 45px !important;
}

@media only screen and (max-width: 834px) {
  .qa dt {
    padding-right: 45px !important;
  }
}

.qa dd {
  width: 100% !important;
  border: none !important;
  padding: 15px 0 15px 45px !important;
  position: relative;
}

.qa dd span {
  font-size: 1.25em;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: bold;
}

.qa dt:before {
  content: "Q";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #272e77;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 10px;
}

.qa dd:before {
  content: "A";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #6157ba;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  margin-right: 7px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 10px;
}

.con100 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.imgbox {
  justify-content: space-between;
  margin-top: 10px;
}

.imgbox .box {
  width: 30%;
}

.imgbox .box:nth-of-type(2) {
  margin: 0 10px;
}

@media only screen and (max-width: 640px) {
  .imgbox .box:nth-of-type(2) {
    margin: auto;
  }
}

@media only screen and (max-width: 640px) {
  .imgbox .box {
    width: 100%;
    margin: 15px 0;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .imgbox .box {
    width: 27%;
  }
}

.imgbox .box img {
  width: 100% !important;
  height: 110px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media only screen and (max-width: 640px) {
  .imgbox .box img {
    height: auto !important;
    width: 100% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgbox .box img {
    height: 150px !important;
  }
}

.imgbox .box article div {
  text-align: center;
  background: #6157ba;
  padding: 5px;
  color: #ffffff;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.blog {
  text-align: left;
}

.txt_waku div {
  font-size: 1em;
  font-weight: bold;
  line-height: 1.5em;
  background: linear-gradient(20deg, #E9397A 41%, #EE7625 55%);
  padding: 20px;
  color: #ffffff;
}

@media only screen and (max-width: 834px) {
  .txt_waku div {
    text-align: left !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  .txt_waku div {
    text-align: left !important;
  }
}

.gmap {
  z-index: 0;
}

.gmap iframe {
  width: 100%;
}

.slider2 img {
  margin: 0 auto;
}

.bg_100per_wrap {
  position: relative;
  padding: 500px 0 !important;
}

@media only screen and (max-width: 640px) {
  .bg_100per_wrap {
    padding: 550px 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/cont2_bg.jpg);
  background-size: cover;
  z-index: 0;
  display: flex;
  justify-content: flex-end;
  background-attachment: fixed;
}

@media only screen and (max-width: 834px) {
  .bg_100per_inner {
    display: block;
  }
}

.flex {
  display: block !important;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 1px solid #6157ba;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
  color: #111111;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.policy {
  padding: 10px 0;
  text-align: center;
}

.policy a {
  color: #6157ba;
}

.mail {
  display: none;
}

#TRANS_ANNOT {
  display: none;
}

.slide-bottom {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 1500ms;
}

.slide-bottom.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-top {
  opacity: 0;
  transform: translate(0, -50px);
  transition: all 1500ms;
}

.slide-top.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-right {
  opacity: 0;
  transform: translate(50px, 0px);
  transition: all 1500ms;
}

.slide-right.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-left {
  opacity: 0;
  transform: translate(-50px, 0px);
  transition: all 1500ms;
}

.slide-left.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

@keyframes fadeIn_left {
  0% {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn_right {
  0% {
    opacity: 0;
    transform: translate(50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
  }
}

.sns {
  margin-top: 50px;
}

#media {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
}

#media .row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

#media .row .box {
  width: 31%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 640px) {
  #media .row .box {
    width: 100% !important;
  }
}

#media .row .box h3 {
  text-align: left;
  margin-top: 15px;
}

#media .row .box p {
  text-align: left;
}

#media .row .box img {
  width: 380px !important;
  height: 380px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}
